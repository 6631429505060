import { StaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import ReactGA from "react-ga"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Cookies } from "react-cookie-consent"

const link =
  "https://apps.apple.com/us/app/mente-todo-liste-og-coach/id1485896552?ls=1"

export function downloadAction() {
  // TODO: log + deeplink for de som allerede har appen
  window.open(link)
}

const DownloadButton = ({ data, type, className = "", label }) => {
  const [isSupportedPlatform, setIsSupportedPlatform] = useState(false)

  useEffect(() => {
    setIsSupportedPlatform(
      /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.platform)
    )
  }, [])

  function handleClick(evt) {
    if (
      Cookies.get("gatsby-gdpr-facebook-pixel") === `true` &&
      typeof window.fbq === `function`
    ) {
      window.fbq("track", "InitiateCheckout")
    }

    if (Cookies.get("gatsby-gdpr-google-analytics") === `true`) {
      ReactGA.event({
        category: "CTA",
        action: "Download app",
        label: label,
      })
    }
  }
  if (type === "small") {
    if (isSupportedPlatform) {
      return (
        <a
          href={link}
          onClick={handleClick}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-cta"
        >
          Last ned gratis
        </a>
      )
    } else {
      return (
        <Link to="/pricing" className="btn-cta">
          Last ned gratis
        </Link>
      )
    }
  } else if (type === "link") {
    return (
      <a
        href={link}
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        Last ned gratis
      </a>
    )
  } else {
    return (
      <div className={className}>
        <a
          href={link}
          onClick={handleClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img
            fixed={data.downloadAppstore.childImageSharp.fixed}
            className={`download-btn`}
            alt="Last ned fra App Store"
          />
        </a>
      </div>
    )
  }
}

const WrappedDownloadButton = (props) => (
  <StaticQuery
    query={graphql`
      query {
        downloadAppstore: file(relativePath: { eq: "download-appstore.png" }) {
          childImageSharp {
            fixed(width: 188) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => <DownloadButton data={data} {...props} />}
  />
)

export default WrappedDownloadButton;