import { Link } from "gatsby"
import React from "react"
import Logo from "./logo"
import Download from "./download"

const Footer = () => (
  <footer id="footer">
    <div className="grid footer-content">
      <div className="footer-1">
        <Link to="/" style={{ textDecoration: "none" }}>
          <Logo className="footer-logo" />
        </Link>
        <h3 className="footer-name">Mente</h3>
        <p style={{ margin: "0" }}>
          Laget med{" "}
          <span role="img" aria-label="kjærlighet">
            🖤
          </span>{" "}
          i Norge
        </p>
      </div>
      <div className="footer-2">
        <h3>Appen</h3>
        <ul>
          <li>
            <Link to="/">Bli kjent</Link>
          </li>
          <li>
            <Link to="/pricing">Pris</Link>
          </li>
          <li>
            <Download type="link" label="Footer" />
          </li>
          <li>
            <Link to="/terms/">Brukervilkår</Link>
          </li>
          <li>
            <Link to="/privacy/">Personvernerklæring</Link>
          </li>
        </ul>
      </div>
      <div className="footer-3">
        <h3>Hjelp</h3>
        <ul>
          <li>
            <Link to="/help/">Spørsmål og svar</Link>
          </li>
          <li>
            <Link to="/help/payment">Betaling</Link>
          </li>
          <li>
            <a href="mailto:hello@mente.app">hello@mente.app</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer
