import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo"
import DownloadButton from "./download"

const Header = ({ data }) => (
  <header id="header">
    <Link to="/" style={{ display: "inline-block", textDecoration: "none" }}>
      <Logo />
      <div className="logo-name">Mente</div>
    </Link>
    <div
      style={{
        position: "absolute",
        right: 0,
        top: "10px",
        fontSize: "0.8rem",
      }}
      id="header-download"
    >
      <DownloadButton type="small" label="Header" />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
