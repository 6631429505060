import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Logo = ({ className = "" }) => {
  return (
    <StaticImage
      src="../images/icon.png"
      alt="Mente logo"
      width={100}
      layout="fixed"
      placeholder="none"
      className={`logo-img ${className}`}
    />
  )
}

export default Logo
